/** @jsx jsx */
import { jsx, Flex, Box, Styled, Button } from 'theme-ui';
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout, Container } from '../components';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import BlogCard from '../components/BlogCard';
import { Tabs, TabItem } from '../components/Tabs';
import capitalize from 'lodash/capitalize';
import union from 'lodash/union';
import flatten from 'lodash/flatten';
import kebabCase from 'lodash/kebabCase';
import * as analytics from '../utils/analytics';
import { checkLanguageVersion } from '../utils/contentful';
import { filterByChannel } from '../hooks/useBlogPosts';

const BlogPage = ({ pageContext, data, location }) => {
  analytics.usePageCategory('blog');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const allPosts = data.allContentfulBlogPost.edges.filter(filterByChannel);
  const tags = union(
    flatten(
      allPosts
        .filter(edge => edge.node.node_locale.startsWith(locale) && edge.node.tags != null)
        .map(post => post.node.tags)
    ).map(t => kebabCase(t))
  );

  const [filter, setFilter] = useState((location.state && location.state.tag) || tags?.[0]);
  const posts =
    filter === 'all'
      ? allPosts
      : allPosts
          .filter(edge => edge.node.node_locale.startsWith(locale) && edge.node.tags != null)
          .filter(edge => edge.node.tags.map(t => t.toLowerCase()).includes(filter));

  return (
    <Layout title={translate('blog')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container>
        <Styled.h1 sx={{ textAlign: 'center', fontSize: [6, null, 7] }}>{translate('blog')}</Styled.h1>
        {tags && (
          <Tabs>
            {tags.map((tag, i) => (
              <TabItem
                key={i}
                active={filter == tag}
                onClick={e => {
                  e.preventDefault();
                  setFilter(tag);
                }}
              >
                {capitalize(tag)}
              </TabItem>
            ))}
          </Tabs>
        )}

        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center', mx: -3 }}>
          {posts
            // get news for specific locale
            .filter(
              edge => edge.node.node_locale.startsWith(locale) && checkLanguageVersion(locale, edge?.node?.languages)
            )
            .map(({ node }, key) => (
              <Box
                key={key}
                sx={{
                  width: [
                    '100%',
                    key === 0 && filter === 'all' ? '100%' : '50%',
                    key === 0 && filter === 'all' ? '66.66666%' : '33.33333%',
                  ],
                  px: 3,
                  mb: 4,
                }}
              >
                <BlogCard post={node} />
              </Box>
            ))}
        </Flex>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(filter: { postType: { eq: "Blog" } }, sort: { fields: [publishTime], order: DESC }) {
      edges {
        node {
          title
          slug
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          publishTime(formatString: "D.M.Y")
          node_locale
          description
          tags
          keywords
          updatedAt
          languages
          channel
        }
      }
    }
  }
`;

export default BlogPage;
