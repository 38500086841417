/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import Link from './Link';

export const TabItem = props => (
  <Link
    sx={{
      position: 'relative',
      py: 2,
      mx: [2, 3],
      lineHeight: 1,
      color: props.active ? 'primary' : 'text',
      fontWeight: 'medium',
      ':hover': {
        color: 'primary',
      },
      '&::after': {
        position: 'absolute',
        bottom: -1,
        left: 2,
        right: 2,
        height: 2,
        bg: 'primary',
        content: '""',
        width: props.active ? 'auto' : 0,
      },
    }}
    {...props}
  >
    {props.children}
  </Link>
);

export const Tabs = props => (
  <Flex sx={{ justifyContent: 'center', mb: [4, null, 5] }} {...props}>
    {props.children}
  </Flex>
);
